/* eslint-disable */
import i18n from 'i18next';
import React, { useMemo, useEffect } from 'react';

import { ModuleMapping, UserRole } from '@hobt/constants';
import { UserAndAccesses, UserAccess } from '@hobt/user-domain';

import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { pathNames } from 'Constants/pathNames';
import NewUserDetails from 'Feature/UserManagement/components/CardsExternal/NewUserDetails';
import UserContactInfo from 'Feature/UserManagement/components/CardsExternal/UserContactInfo';
import UserPermissions from 'Feature/UserManagement/components/CardsExternal/UserPermissions';
import LevelAccessInternal from 'Feature/UserManagement/components/CardsInternal/LevelAccess';
import UserDetailsInternal from 'Feature/UserManagement/components/CardsInternal/UserDetails';
import {
  ModifyUserActionType,
  ModifyUserAction,
} from 'Feature/UserManagement/components/ModifyUser/types';

export const useModifyUserFunctions = (
  formMethod: { reset: Function; setValue: Function; register: Function },
  isInternal: boolean,
  dispatch: React.Dispatch<ModifyUserAction>,
  push: Function,
  userData: UserAndAccesses | null,
  sitecoreContext?: any
) => {
  const isReadOnlyUser = useMemo(() => {
    const moduleRoleMapping = sitecoreContext?.user?.moduleRoleMapping;
    return (
      isInternal &&
      isUserInRoles(ModuleMapping.admin, [UserRole.ReadOnly], moduleRoleMapping)
    );
  }, []);

  const sectionsLookup: Record<string, React.FC<any>> = isInternal
    ? {
        UserDetailsInternal,
        LevelAccessInternal,
      }
    : {
        NewUserDetails,
        UserContactInfo,
        UserPermissions,
      };

  const registerAndSet = (fieldName: string, value: unknown) => {
    formMethod.register(fieldName);
    formMethod.setValue(fieldName, value);
  };

  const initializeFormValues = () => {
    if (userData != null) {
      formMethod.reset(userData);

      registerAndSet('userTypeCode', userData.userTypeCode);

      // shouldn't register emailID each time because this field is using the useController
      formMethod.setValue('emailID', userData.emailID);

      if (isInternal) {
        userData?.userAccesses?.forEach((userAccess, index) => {
          const userAccessPath = `userAccesses[${index}]`;

          registerAndSet(
            `${userAccessPath}.access.admin`,
            userAccess?.access?.admin
          );
          registerAndSet(
            `${userAccessPath}.access.arrears`,
            userAccess?.access?.arrears
          );
          registerAndSet(
            `${userAccessPath}.access.default`,
            userAccess?.access?.default
          );

          if (userAccess.access?.claim?.activeFlag != null) {
            registerAndSet(
              `${userAccessPath}.access.claim.activeFlag`,
              userAccess?.access?.claim?.activeFlag
            );
          }

          if (userAccess?.access?.claim?.roleCode != null) {
            registerAndSet(
              `${userAccessPath}.access.claim.roleCode`,
              userAccess?.access?.claim?.roleCode
            );
       

            if (userAccess?.access?.claim?.roleCode === UserRole.BusinessUser) {
              registerAndSet(
                `${userAccessPath}.access.claim.probationaryUserFlag`,
                userAccess?.access?.claim?.probationaryUserFlag
              );
            }
          }
        });
        registerAndSet('internalContact.titleTypeCode', userData.internalContact?.titleTypeCode);
        registerAndSet('internalContact.titleName', userData.internalContact?.titleName);
      
        registerAndSet('userAccesses[0].access.default', {
          activeFlag: userData.userAccesses?.[0]?.access?.default?.activeFlag ?? false
        });
        registerAndSet('userAccesses[0].access.claim', {
          activeFlag: userData.userAccesses?.[0]?.access?.claim?.activeFlag ?? false
        });
        registerAndSet('userAccesses[0].access.arrears', {
          activeFlag: userData.userAccesses?.[0]?.access?.arrears?.activeFlag ?? false
        });

        registerAndSet(
          'internalContact.titleTypeCode',
          userData.internalContact?.titleTypeCode
        );
      }

      if (!isInternal) {
        userData.userAccesses?.forEach((userAccess, index) => {
          const access = `userAccesses[${index}].access.admin`;
          const defaultFinancialInstitutionCode = `userAccesses[${index}].defaultFinancialInstitutionCode`;
          const defaultTransitNumber = `userAccesses[${index}].defaultTransitNumber`;
          const financialInstitutionCodes = `userAccesses[${index}].financialInstitutionCodes`;
          const allowedTransitNumbers = `userAccesses[${index}].allowedTransitNumbers`;
          const statusCode = `userAccesses[${index}].statusCode`;

          registerAndSet(access, {
            activeFlag: userAccess?.access?.admin?.activeFlag ?? false,
          });

          registerAndSet(
            defaultFinancialInstitutionCode,
            userAccess?.defaultFinancialInstitutionCode ?? '000'
          );

          registerAndSet(
            defaultTransitNumber,
            userAccess?.defaultTransitNumber ?? '00000'
          );

          registerAndSet(
            financialInstitutionCodes,
            userData?.userAccesses?.map(
              (userinfo: UserAccess) => userinfo?.financialInstitutionCode
            )
          );

          registerAndSet(
            allowedTransitNumbers,
            userAccess?.allowedTransitNumbers ?? []
          );

          registerAndSet(statusCode, userAccess?.statusCode);
        });

        
      }
    }
  };

  const isEditingOwnProfile =
    sitecoreContext?.user?.userID === userData?.userID;

  useEffect(() => {
    formMethod.register('emailID');
  }, []);

  useEffect(() => {
    initializeFormValues();
  }, [userData]);

  return {
    isEditingOwnProfile,
    isReadOnlyUser,
    sectionsLookup,
    initializeFormValues,
    onCancel: () => {
      push(`/${i18n.language}${pathNames.userInventory}`);
    },
    onDeleteAccountButtonClick: () => {
      dispatch({ action: ModifyUserActionType.SHOW_MODAL });
    },
    onDismissToast: () => {
      dispatch({ action: ModifyUserActionType.DISMISS_TOAST });
    },
    onDismissModal: () => {
      dispatch({ action: ModifyUserActionType.DISMISS_MODAL });
    },
    onDismissUpdateModal: () => {
      dispatch({ action: ModifyUserActionType.DISMISS_UPDATE_MODAL });
    }
  };
};
