import React, { useState, useEffect } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import i18n from 'i18next';
import { useForm } from 'react-hook-form';
import { Prompt } from 'react-router-dom';
import { withFeature } from 'flagged';
import { joiResolver } from '@hookform/resolvers/joi';

import { ModuleMapping, UserRole } from '@hobt/constants';
import { defaultsPortal } from '@hobt/form-schema-validators';

import { ICard } from 'Feature/DefaultSubmissionForm/components/Card.types';
import {
  DefaultManagementTools,
  getDefaultManagementToolsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/DefaultManagementTools';
import { TargetSection } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import ContentBlockInCard from 'Feature/PageComponents/components/ContentBlockInCard';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
// Analytics Service
import useCustomEvent from 'Foundation/Analytics/useCustomEvent';
import { useAppInsightsContext } from 'Foundation/Analytics/AppInsightsContext';
import { removeEmptyFields } from 'Constants/Utils/RemoveEmptyFields';
import { Button, ButtonType } from '../../../../CommonComponents/UserControls';
import {
  DefaultDetailSubmissionFormProps,
  SitecoreItem
} from './DefaultDetailSubmissionForm.types';

import styles from './styles.module.scss';
import _ from 'lodash-es';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const DefaultDetailSubmissionFormWorkoutPlan: React.FC<DefaultDetailSubmissionFormProps> = ({
  detailsData,
  onSubmitCallback,
  setDisableCardBtns,
  defaultSubmissionFormProps
}) => {
  // manually setting own isDirty check for stage three tool deletions
  const [stageThreeToolDeleted, setStageThreeToolDeleted] = useState<boolean>(false);
  const [isSidedrawerActive, setSidedrawerActive] = useState<boolean>(false);

  // App Insights
  const reactPlugin = useAppInsightsContext();
  const trackDefaultSubmission = useCustomEvent(
    reactPlugin,
    'Default Detail Submission Form Workout Plan',
    {}
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    watch,
    reset,
    formState
  } = useForm({
    resolver: joiResolver(defaultsPortal),
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  });

  const { isDirty } = formState;

  const commonCardProps: ICard = {
    register,
    errors,
    setValueHandler: setValue,
    control,
    watch,
    getValues,
    reset,
    setStageThreeToolDeleted
  };

  // Form Handling
  const onSubmit = (formData: any) => {
    const strippedValues = removeEmptyFields(formData);

    setStageThreeToolDeleted(false);
    onSubmitCallback(true, strippedValues.managementInformation);
  };

  const onError = (errs: any) => {
    trackDefaultSubmission({ frontendFormErrors: errs });
    setDisableCardBtns(true);
  };

  useEffect(() => {
    return () => {
      if (isServer() === false) {
        // clean up onbeforeunload, on component unmount
        window.onbeforeunload = null;
      }
    };
  }, []);

  // make a copy of detailsData, that stays consistent through form edits
  const detailsDataOriginal = _.cloneDeep(detailsData);

  const mgmtInfo = detailsData.managementInformation;

  const setValueData = () => {
    setStageThreeToolDeleted(false); // disable save and cancel btns

    register('managementInformation.description');
    setValue('managementInformation.description', mgmtInfo.description);

    register('managementInformation.causeOfDefault');
    setValue('managementInformation.causeOfDefault', mgmtInfo.causeOfDefault);

    register('managementInformation.dateHardshipBegan');
    setValue('managementInformation.dateHardshipBegan', mgmtInfo.dateHardshipBegan);

    register('managementInformation.monitoringStrategy');
    setValue('managementInformation.monitoringStrategy', mgmtInfo.monitoringStrategy);

    register('managementInformation.submittedDefaultStage');
    setValue('managementInformation.submittedDefaultStage', mgmtInfo.submittedDefaultStage);

    register('managementInformation.lenderAcknowledgementSignatureTitle');
    setValue(
      'managementInformation.lenderAcknowledgementSignatureTitle',
      mgmtInfo.lenderAcknowledgementSignatureTitle
    );

    register('managementInformation.lenderAcknowledgementSignatureFullName');
    setValue(
      'managementInformation.lenderAcknowledgementSignatureFullName',
      mgmtInfo.lenderAcknowledgementSignatureFullName
    );

    register('managementInformation.lenderAcknowledgementAdditionalDetails');
    setValue(
      'managementInformation.lenderAcknowledgementAdditionalDetails',
      mgmtInfo.lenderAcknowledgementAdditionalDetails
    );

    register('managementInformation.preApprovalRequired');
    setValue('managementInformation.preApprovalRequired', mgmtInfo.preApprovalRequired);
  };

  useEffect(() => {
    const updatedData = {
      ...detailsDataOriginal,
      managementInformation: {
        ...detailsDataOriginal.managementInformation,
        ...(detailsDataOriginal.managementInformation.submittedStageOne == null && {
          submittedStageOne: {}
        }),
        ...(detailsDataOriginal.managementInformation.submittedStageTwo == null && {
          submittedStageTwo: {}
        }),
        ...(detailsDataOriginal.managementInformation.submittedStageThree == null && {
          submittedStageThree: {}
        }),
        ...(detailsDataOriginal.managementInformation.submittedStageFour == null && {
          submittedStageFour: {}
        }),
        ...(detailsDataOriginal.managementInformation.proposedStageOne == null && {
          proposedStageOne: {}
        }),
        ...(detailsDataOriginal.managementInformation.proposedStageTwo == null && {
          proposedStageTwo: {}
        }),
        ...(detailsDataOriginal.managementInformation.proposedStageThree == null && {
          proposedStageThree: {}
        }),
        ...(detailsDataOriginal.managementInformation.proposedStageFour == null && {
          proposedStageFour: {}
        })
      }
    };
    reset(updatedData);
    setValueData();
    setTimeout(() => {
      // always place setValue at end of calls
      setValue(
        'managementInformation.proposedStageThree',
        detailsDataOriginal.managementInformation.proposedStageThree
      );
    }, 0);
  }, [mgmtInfo]);

  const formErrorsLength = Object.keys(formState.errors).length;

  // isDirty check
  const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if (isDirtyAlt) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }

        return null;
      };
    }

    setDisableCardBtns(isDirtyAlt || formErrorsLength > 0);
  }, [isDirtyAlt, formErrorsLength]);

  const prepopToolsLength = () => {
    const stageThreeDataObj = mgmtInfo.submittedStageThree;
    return (
      stageThreeDataObj.combinationFlag &&
      stageThreeDataObj.toolDetail &&
      stageThreeDataObj.toolDetail.length
    );
  };

  const proposedStageThreeLength = () => {
    if (mgmtInfo.proposedStageThree !== undefined) {
      const stageThree = mgmtInfo.proposedStageThree;
      return stageThree.combinationFlag && stageThree.toolDetail.length - 1;
    }
    return 0;
  };

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext: any = sitecoreContextFactory?.sitecoreContext;

  const drawerOpenLabel =
    sitecoreContext.route.placeholders['hbt-main']?.find((item: SitecoreItem) => {
      return item.componentName === 'DefaultInventoryDetails';
    })?.fields.drawerOpenLabel?.value ?? '';
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isClerkUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.Clerk],
    moduleRoleMapping
  );
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.default,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  const formButtons = (index: number) => (
    <>
      <Button
        id={`defaultManagementToolsWorkoutPlanButtonSave${index}`}
        displayText={i18n.t('DefaultSubmission-Card-WorkoutPlan-SaveButton')}
        ariaText={i18n.t('DefaultManagementToolsWorkoutPlanButtonSave')}
        buttonType={ButtonType.PRIMARY}
        disabled={
          isReadOnlyUser ||
          isClerkUser ||
          (formState.isDirty === false && stageThreeToolDeleted === false)
        }
        name="default_management_tools_workout_plan_button_save"
        onClick={handleSubmit(onSubmit, onError)}
      />
      <Button
        id={`defaultManagementToolsWorkoutPlanButtonCancel${index}`}
        displayText={i18n.t('DefaultSubmission-Card-WorkoutPlan-CancelButton')}
        ariaText={i18n.t('DefaultManagementToolsWorkoutPlanButtonCancel')}
        buttonType={ButtonType.SECONDARY}
        disabled={formState.isDirty === false && stageThreeToolDeleted === false}
        name="default_management_tools_workout_plan_button_cancel"
        onClick={() => {
          // order matters
          reset();
          setValueData();
          setTimeout(() => {
            // always place setValue at end of calls
            setValue(
              'managementInformation.proposedStageThree',
              detailsDataOriginal.managementInformation.proposedStageThree
            );
          }, 0);
        }}
        otherProps={{ type: 'button' }}
      />
    </>
  );

  return (
    <>
      {/* React Router prompt on route navigations */}
      <Prompt when={isDirtyAlt} message={i18n.t('DefaultSubmission-IsDirty')} />
      <form
        id="defaultInventoryDetailsFormWorkoutPlan"
        name="default-inventory-details-form"
        className="form default-submission-form"
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
        noValidate
      >
        <div>
          <div className={styles.layoutContainer}>
            <div className={styles.headerText}>
              <ContentBlockInCard
                fields={{
                  heading: {
                    field: {
                      value: i18n.t('DefaultDetailSubmissionFormWorkoutPlanHeader')
                    }
                  },
                  content: {
                    field: {
                      value: i18n.t('DefaultDetailSubmissionFormWorkoutPlanSubText')
                    }
                  }
                }}
              />
            </div>
            <div className={styles.showForDesktopOnly}>
              <div className={styles.cardHeaderActionButtons}>{formButtons(0)}</div>
            </div>
          </div>
          <div className={`${styles.openSideDrawer} ${styles.hideForDesktop}`}>
            <button
              id="openSidedrawerButton"
              className={styles.openSideDrawerButton}
              onClick={(e) => {
                e.preventDefault();
                setSidedrawerActive(true);
              }}
            >
              <label htmlFor="openSidedrawerButton"> {drawerOpenLabel} </label>
            </button>
          </div>
          <div className={styles.workoutBodyLayout}>
            <div>
              <div
                className={`${styles.sideDrawer} ${
                  isSidedrawerActive ? '' : styles.hideSideDrawer
                }`}
              >
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    setSidedrawerActive(false);
                  }}
                >
                  {' '}
                </div>
                <div>
                  <div
                    className={`${styles.hideForDesktop} ${styles.closeWrapper}`}
                    onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                      if (e.key === 'Enter') {
                        setSidedrawerActive(false);
                      }
                    }}
                  >
                    <span
                      className={styles.closebtn}
                      tabIndex={0}
                      aria-label={i18n.t('Accessibility-Close-Button')}
                      onClick={() => {
                        setSidedrawerActive(false);
                      }}
                      role="button"
                    />
                  </div>

                  <fieldset disabled={true}>
                    <DefaultManagementTools
                      {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                      {...commonCardProps}
                      id="defaultManagementToolsWorkoutPlanTabSection1"
                      title={{
                        field: {
                          value: i18n.t(
                            'DefaultSubmission-Card-DefaultManagementToolsSubmittedStageHeading'
                          )
                        }
                      }}
                      targetSection={TargetSection.DEFAULTSUBMISSION}
                      cardCount={prepopToolsLength()}
                      linedCardProps={{
                        id: 'defaultManagementToolsWorkoutPlanTabSection1_2',
                        testId: 'defaultManagementToolsTest1'
                      }}
                      disableCalendar={true}
                      expandRange={true}
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            <div className={styles.cardNoMargin}>
              <DefaultManagementTools
                {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="defaultManagementToolsWorkoutPlanTabSection2"
                title={{
                  field: {
                    value: i18n.t(
                      'DefaultSubmission-Card-DefaultManagementToolsProposedStageHeading'
                    )
                  }
                }}
                targetSection={TargetSection.WORKOUTPLAN}
                cardCount={proposedStageThreeLength()}
                linedCardProps={{
                  id: 'defaultManagementToolsWorkoutPlanTabSection2_2',
                  testId: 'defaultManagementToolsTest2'
                }}
                expandRange={true}
              />
              <div className={styles.footerButtonContainer}>
                <div className={styles.footerButtons}>{formButtons(1)}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withFeature(FeatureFlags.INTERNAL)(DefaultDetailSubmissionFormWorkoutPlan);
