import { SitecoreDropdownList } from 'Constants/Types/SitecoreListItem';
import { ImageProps } from '@sitecore-jss/sitecore-jss-react/types/components/Image';
import { AttachmentsViewPropsFields } from 'Components/Common/AttachmentsView/types';
import { HbtFileExtensionType } from '@hobt/constants';

export default interface ManageUsersExternalProps {
  fields: ManageUsersExternalPropsFields;
}
export interface DownloadReportTypeProps {
  fields: {
    modalTitle: FieldValue;
    submitBtn: FieldValue;
    cancelBtn: FieldValue;
    years: FieldValue;
    months: FieldValue;
  };
  isActive: () => void;
  isSuccess: (result: any) => any;
}

export interface ManageUsersExternalPropsFields {
  pageTitle: FieldValue;
  userLabel: FieldValue;
  addUserBtn: FieldValue;
  statusLabel: FieldValue;
  description: FieldValue;
  claimsLabel: FieldValue;
  arrearsLabel: FieldValue;
  defaultLabel: FieldValue;
  checkLabel: FieldValue;
  telephoneLabel: FieldValue;
  extensionLabel: FieldValue;
  lastLogInLabel: FieldValue;
  downloadReport: FieldValue;
  tableAccessibilityText: FieldValue;
  typeOfUserLabel: FieldValue;
  uploadBtn: FieldValue;
  uploadFiles: UploadFileTypeProps;
  defaultFITransit: FieldValue;
  accountStatus: SitecoreDropdownList;
  userRoles: SitecoreDropdownList;
  externalTemplateLink: MediaDocumentProps;
  externalTemplateBtn: FieldValue;
  downloadUserReport: DownloadReportTypeProps;
}

export interface UserMangementExternalInventoryRecords {
  userID: string;
  emailID?: string;
  recordUpdatedTimestamp?: string;
  fullName?: string;
  statusCode?: number;
  phoneNumber?: string;
  extensionPhoneNumber?: string;
  typeOfUser?: string;
  arrearsAccessFlag?: boolean;
  claimAccessFlag?: boolean;
  defaultAccessFlag?: boolean;
  defaultFinancialInstitutionCodeAndTransitNumber?: string;
  loginTimestamp?: string;
}

interface MediaDocumentProps {
  fields: {
    title: GenericLinkItem;
  };
}

export interface UploadFileTypeProps {
  fields: {
    email: FieldValue;
    errorCode: FieldValue;
    errorMessage: FieldValue;
    modalTitle: FieldValue;
    uploadBtn: FieldValue;
    cancelBtn: FieldValue;
    downloadBtn: FieldValue;
    uploadFileNameLabel: FieldValue;
    deleteIcon: ImageProps;
    attachmentsView: AttachmentsViewComponentProps;
  };
  isActive: () => void;
}

export interface AttachmentsViewComponentProps {
  fields: AttachmentsViewPropsFields;
}

export const AllowedFileTypes = [HbtFileExtensionType.XLSX, HbtFileExtensionType.XLS];

export interface BulkUserUploadErrorResp {
  emailID: string;
  error: UploadError;
}

interface UploadError {
  appErrorMessage: string;
  appErrorName: string;
  code: string;
  httpCode: number;
  location: string;
  name: string;
}
