/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useFieldArray, FormProvider, useFormContext, UseFieldArrayRemove } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useFeature } from 'flagged';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Text, Image, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import { HbtFileExtensionType, Module, ModuleMapping, Status, UserRole } from '@hobt/constants';
import { getCurrentTimestampDate10Decimal6 } from '@hobt/utils';

import FormText from 'Components/Inputs/FormText';
import { SideDrawer } from 'Components/PageComponents/SideDrawer';
import { AttachmentsView } from 'Components/Common/AttachmentsView';
import { useAuthenticationContext } from 'Foundation/Authentication';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';
import { DataObject as ApprovalFormDataObject } from 'Feature/ApprovalsFinancialAuthority/components/ApprovalForm/types';
import { LinedCard, AccordionContainer } from 'Feature/CommonComponents/AccordionComponents';
import ArrearsReportProps, {
  ArrearsAttachment,
  ReplacementAttachment
} from 'Feature/Arrears/models/ArrearsReportProps';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

import {
  downloadFileRequest,
  getFilesRequest
} from '../../ArrearsSubmissionForm/ArrearsSubmissionService';
import {
  ArrearsFileData,
  ArrearsFilesObject,
  DataObject as ArrearsFilesDataObject
} from '../../ArrearsSubmissionForm/types';
import ReplaceArrears from '../../ReplaceArrears';
import { config } from '../../../../../config';
import styles from '../../ArrearsSubmissionListDetails/styles.module.scss';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const cardId = 'ArrearsFormReport';
const AllowedFileTypes = [
  HbtFileExtensionType.CSV,
  HbtFileExtensionType.XLSX,
  HbtFileExtensionType.XLSM,
  HbtFileExtensionType.XLSB,
  HbtFileExtensionType.XLTX,
  HbtFileExtensionType.XLTM,
  HbtFileExtensionType.XLS,
  HbtFileExtensionType.XLT,
  HbtFileExtensionType.XML,
  HbtFileExtensionType.XLAM,
  HbtFileExtensionType.XLA,
  HbtFileExtensionType.XLW,
  HbtFileExtensionType.XLR
];

interface ArrearsFile {
  transitNumber?: string;
  attachment?: string;
}

interface ArrearsFilesArray {
  fields: ArrearsFile[];
  remove: UseFieldArrayRemove;
  insert: any;
}

const ArrearsReport: React.FC<ArrearsReportProps> = ({
  fields,
  isModify,
  errorCode,
  errorData,
  filesMethods,
  setSubmitFailed,
  setArrearsToast,
  setIsLoadingCallback,
  activeReportData
}: ArrearsReportProps) => {
  const {
    cardTitle,
    deleteIcon,
    disabledDeleteIcon,
    replaceIcon,
    fileNameLabel,
    cardSubheading,
    replaceFileText,
    transitNumLabel,
    lastModifiedLabel
  } = fields;

  const isInternalSite = useFeature(FeatureFlags.INTERNAL);
  const authenticationContext = useAuthenticationContext();
  const urlParams = new URLSearchParams(!isServer() ? window.location.search : '');
  const linkId = urlParams.get('id') || '';

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const userDetails = sitecoreContext && sitecoreContext.user;
  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    ModuleMapping.arrears,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );
  const [flyoutData, setFlyoutData] = useState<ReplacementAttachment>({});
  const [isAllowed, setIsAllowed] = useState<boolean>(false);
  const [indexCounter, setIndexCounter] = useState<number>(0);
  const [showSideDrawer, setShowSideDrawer] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<ArrearsAttachment[]>([]);
  const [deleteDocumentId, setDeleteDocumentId] = useState<string>('');
  const [showReplaceButton, setShowReplaceButton] = useState<boolean>(false);

  // get form methods
  const { watch: submissionInfoWatch } = useFormContext();
  const { control, register, unregister, setValue, watch } = filesMethods;

  const {
    fields: arrearsFiles,
    remove,
    insert
  }: ArrearsFilesArray = useFieldArray({
    control,
    name: 'arrearsFiles'
  });

  const getArrearsFiles = async (id: string) => {
    const res: ArrearsFilesDataObject = await getFilesRequest(authenticationContext, id);
    setAttachments(res.data);
  };

  useEffect(() => {
    // initialize attachments
    register(`arrearsFiles[${indexCounter}].attachment`);

    if (isModify === true && linkId.length > 0) {
      getArrearsFiles(linkId);
    }
  }, []);

  useEffect(() => {
    activeReportData?.filter((data) => {
      if (data?.reportingPeriod === submissionInfoWatch('submission.reportingPeriod')) {
        setShowReplaceButton(true);
      }
    });
  }, [activeReportData]);

  useEffect(() => {
    // update form when the field array changes
    arrearsFiles.forEach((_, index) => {
      register(`arrearsFiles[${index}].transitNumber`);
      register(`arrearsFiles[${index}].attachment`);
      setValue(`arrearsFiles[${index}].transitNumber`, arrearsFiles[index].transitNumber);
      setValue(`arrearsFiles[${index}].attachment`, arrearsFiles[index].attachment);
    });
  }, [arrearsFiles]);

  const deleteAttachment = (documentId: string, index?: number) => {
    const newAttachments = (attachments as ArrearsAttachment[]).filter(
      (attachmentWrapper: ArrearsAttachment) => {
        if (attachmentWrapper?.attachment?.documentID !== documentId) {
          return true;
        }
        setDeleteDocumentId(documentId);
        return false;
      }
    );

    // remove from arrears files array
    remove(index);
    unregister(`arrearsFiles[${index}]`);

    setAttachments(newAttachments);
    setIndexCounter(newAttachments.length < 1 ? 0 : newAttachments.length);
  };

  const replaceAttachment = (documentId: string) => {
    setShowSideDrawer(true);
    setFlyoutData({
      documentId,
      detailsId: linkId,
      userId: userDetails?.userID,
      userType: userDetails?.userTypeCode
    });
  };

  const downloadFile = (file: any) => {
    const fileId = file.arrearsFileID;
    const docId = file.attachment.documentID;

    downloadFileRequest(authenticationContext, docId, fileId)
      .then((res: AxiosResponse<ApprovalFormDataObject>) => {
        const downloadElement = document.createElement('a');

        downloadElement.setAttribute('href', res.data.data.documentEntityJson.documentLink);
        downloadElement.click();
        downloadElement.remove();
      })
      .catch((err: any) => {
        /* eslint-disable no-console */
        console.log('download err', err);
      });
  };

  const handleTransitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fiCodeWatch = submissionInfoWatch('submission.contact.financialInstitutionCode');

    // TODO - use this later for FE validation on transit number field
    if (event?.target?.value?.length === 5 && fiCodeWatch.length === 3) {
      const allowedNums =
        userDetails.moduleFiTransitMapping[Module.Arrears][fiCodeWatch]?.allowedTransitNumbers ||
        [];
      const matchingTransit = allowedNums.filter((num: string) => event.target.value === num);

      if (!isInternalSite) {
        setIsAllowed(matchingTransit[0] != null);
      } else setIsAllowed(true);
    }

    // this will overwrite above block for now
    setIsAllowed(event?.target?.value?.length === 5);
  };

  const createFileObject = (file: any, index: number) => {
    const attachmentObj: ArrearsFileData = {
      fileName: file.attachment.fileName,
      submitterUserID: userDetails?.userID,
      documentID: file.attachment.documentID,
      submitterUserTypeCode: userDetails?.userTypeCode,
      fileByteCount: file.attachment.fileByteCount,
      recordCreatedTimestamp: getCurrentTimestampDate10Decimal6()
    };

    const filesObject: ArrearsFilesObject = {
      transitNumber: file.transitNumber,
      attachment: attachmentObj
    };

    // add a new file object to field array, remove item initially for safety
    remove(index);
    insert(index, filesObject);
  };

  const addFileObjectAndUpdateArrearsReport = () => {
    if (!isModify) {
      const filesArr = watch(`arrearsFiles`);

      if (Array.isArray(filesArr) && filesArr.length > 0) {
        const filesWithAttachments = filesArr.filter(
          (file: ArrearsFilesObject) => file.attachment !== undefined
        );

        if (
          filesWithAttachments[filesWithAttachments.length - 1].attachment?.uploadStatusCode ===
          Status.UploadSuccessful
        ) {
          // disable the drop file after upload
          setIsAllowed(false);

          // updates
          setAttachments(filesWithAttachments);
          setIndexCounter(filesWithAttachments.length);

          createFileObject(
            filesWithAttachments[filesWithAttachments.length - 1],
            filesWithAttachments.length - 1
          );
        }
      }
    }
  };

  return (
    <>
      <FormProvider {...filesMethods}>
        <LinedCard id={cardId} testId={cardId} role="form" linePosition="vertical" lineColor="grey">
          <AccordionContainer accordionId={`${cardId}Accordion`} title={cardTitle}>
            {/* FILE TABLE VIEW ONLY */}
            {attachments && attachments.length > 0 && (
              <div className="row card__body-row ">
                <div className="col-12">
                  <div className="attachments-table">
                    <table className={`table__container ${styles.arrearsReportTable}`}>
                      <tbody>
                        <tr className="table__row">
                          <th className="table__header">
                            <Text field={transitNumLabel} />
                          </th>
                          <th className="table__header">
                            <Text field={lastModifiedLabel} />
                          </th>
                          <th className="table__header">
                            <Text field={fileNameLabel} />
                          </th>
                          <th className="table__header"></th>
                        </tr>
                      </tbody>
                      {attachments?.map((file: any, index: number) => (
                        <React.Fragment key={index}>
                          <tbody
                            className={
                              errorCode != null &&
                              errorCode === 'transit' &&
                              errorData != null &&
                              errorData.includes(file.transitNumber)
                                ? styles.arrearsReportInvalidRow
                                : ''
                            }
                          >
                            <tr className="table__row" key={index}>
                              <td className={`table__data`}>{file.transitNumber}</td>
                              <td className={`table__data`}>
                                {
                                  `${
                                    isModify === true
                                      ? file.recordUpdatedTimestamp
                                      : getCurrentTimestampDate10Decimal6()
                                  }`.split(' ')[0]
                                }
                              </td>
                              {isModify === true ? (
                                <td className={`table__data`}>
                                  <button
                                    className={styles.buttonLink}
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                      e.preventDefault();
                                      downloadFile(file);
                                    }}
                                  >
                                    {file.attachment.fileName}
                                  </button>
                                </td>
                              ) : (
                                <td className={`table__data`}>{file.attachment.fileName}</td>
                              )}
                              <td className={`table__data`}>
                                <div className="table__icon-group">
                                  {/* TODO : what is behavior once file replaced */}
                                  <button
                                    className={`btn btn--icon ${
                                      !isModify
                                        ? 'delete-attachment-icon'
                                        : file.statusCode === 4 && isInternalSite === true
                                        ? showReplaceButton
                                          ? styles.replaceButton
                                          : styles.displayHidden
                                        : styles.displayHidden
                                    }`}
                                    data-testid="manageAttachmentBtn"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      if (!isModify)
                                        deleteAttachment(file.attachment.documentID, index);
                                      else if (file.statusCode === 4) {
                                        replaceAttachment(file.attachment.documentID);
                                      }
                                    }}
                                    disabled={isReadOnlyUser}
                                  >
                                    <Image
                                      field={
                                        !isModify
                                          ? errorCode != null &&
                                            errorCode === 'transit' &&
                                            errorData != null &&
                                            errorData.includes(file.transitNumber)
                                            ? disabledDeleteIcon
                                            : deleteIcon
                                          : file.statusCode === 4
                                          ? replaceIcon
                                          : {}
                                      }
                                      className="icon-24"
                                    />
                                    {isModify === true && file.statusCode === 4 && (
                                      <Text field={replaceFileText} />
                                    )}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </React.Fragment>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            )}

            {!isModify && (
              <h3 className="card__body-subHeading--text">
                {cardSubheading && cardSubheading.value}
              </h3>
            )}

            {/* UPLOAD FILES VIEW ONLY */}
            {!isModify && (
              <div className="card_body-section obligation-section-1">
                <fieldset disabled={indexCounter >= 20}>
                  <div className="row card__body-row">
                    <div className="form__element form__element--2-column col-6 mb-3">
                      <FormText
                        key={indexCounter}
                        name={`arrearsFiles[${indexCounter}].transitNumber`}
                        reRegisterOnRender={true}
                        className=""
                        label={transitNumLabel}
                        handleChange={handleTransitNumber}
                        isReadOnly={isReadOnlyUser}
                      />
                    </div>
                  </div>
                </fieldset>

                <br />

                {React.createElement(AttachmentsView, {
                  isAllowed,
                  isArrears: true,
                  deleteDocumentId,
                  allowedFileTypes: AllowedFileTypes,
                  registeredVal: 'arrearsFiles',
                  fields: fields.attachmentsView.fields,
                  endpoint: config.arrearsApi.urls.upload,
                  fieldArray: {
                    array: arrearsFiles as any,
                    processFileOnUploadSuccess: addFileObjectAndUpdateArrearsReport,
                    insert,
                    remove
                  }
                })}
              </div>
            )}
          </AccordionContainer>
        </LinedCard>
        <SideDrawer isActive={showSideDrawer} handleOutsideClick={() => setShowSideDrawer(false)}>
          <ReplaceArrears
            flyoutData={flyoutData}
            name="replaceArrearsFlyoutForm"
            fields={fields.replaceArrearsFlyout.fields}
            onCancelCallback={() => setShowSideDrawer(false)}
            setIsLoadingCallback={setIsLoadingCallback}
            onErrorCallback={() => {
              setSubmitFailed(true);
              setArrearsToast(true);
            }}
            onSuccessCallback={() => {
              setSubmitFailed(false);
              setArrearsToast(true);
            }}
          />
        </SideDrawer>
      </FormProvider>
    </>
  );
};

export default ArrearsReport;
