import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useFeature } from 'flagged';
import i18n from 'i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { CommentType } from '@hobt/claim-domain';

import { useCorrespondenceForm } from 'Feature/Claims/components/Details/AttachmentsNotesCommentsDetails/useCorrespondenceForm';
import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';
import { Button } from 'Components/Common/Button';
import FormTextArea from 'Components/Inputs/FormTextArea';
import { AttachmentsView } from 'Components/Common/AttachmentsView';

import { config } from '../../../../../../config';
import { AddCommentFormProps, AllowedFileTypes } from './types';
import styles from './styles.module.scss';

const sectionId = 'ClaimsDetailsAddComment';

export const AddCommentForm: React.FC<AddCommentFormProps> = ({
  fields,
  onSuccessCallback,
  onErrorCallback,
  onCancelCallback
}: AddCommentFormProps) => {
  const isInternal = useFeature(FeatureFlags.INTERNAL);
  const { uuid: claimId } = useHBTFormContext();

  const { hookForm, onSubmit, onCancel } = useCorrespondenceForm(
    onSuccessCallback,
    onErrorCallback,
    onCancelCallback,
    claimId
  );

  useEffect(() => hookForm.setValue('commentTypeCode', CommentType.Comment), []);

  return (
    <Scrollbars>
      <div className={styles.headerWrapper}>
        <Text tag="h2" field={fields.formTitle} />
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid={`${sectionId}FormCloseButton`}
            onClick={onCancel}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>

      <FormProvider {...hookForm}>
        <form
          id={`${sectionId}Form`}
          className={styles.documentRequestWrapper}
          onSubmit={hookForm.handleSubmit(onSubmit)}
          noValidate
        >
          {isInternal && (
            <p className={styles.textWrapper}>
              <Text tag="b" field={fields.cautionText} />
              <Text field={fields.content} />
            </p>
          )}

          <div className={styles.templateWrapper}>
            <FormTextArea
              name="remarkText"
              className={styles.templateTextArea}
              label={fields.description}
              textAreaHelperText={fields.characterLimit.value}
              charLimit={parseInt(fields.limitNumber.value || '0', 10)}
            />
          </div>

          {React.createElement(AttachmentsView, {
            isOptional: true,
            registeredVal: 'attachments',
            fields: fields.attachmentsView.fields,
            endpoint: config.claimApi.urls.upload,
            allowedFileTypes: AllowedFileTypes
          })}

          <div className={styles.buttonWrapper}>
            <Button
              loading
              responsive
              type="submit"
              name={'submitButton'}
              text={fields.submitBtn}
              ariaText={fields.submitBtn}
            />
            <Button
              responsive
              type="button"
              secondaryButton
              name={`cancelButton`}
              onClick={onCancel}
              text={fields.cancelBtn}
              ariaText={fields.cancelBtn}
            />
          </div>
        </form>
      </FormProvider>
    </Scrollbars>
  );
};
