import React, { useState, useEffect } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { FormMultiLineTextProps } from './FormInputMultiLineText.types';
import './FormInputMultiLineText.css';
import GetErrorMessage from '../ErrorMessage';
import _ from 'lodash-es';

const FormInputMultiLineText: React.FC<FormMultiLineTextProps> = (props) => {
  const [text, setText] = useState('');
  const [charNum, setCharNum] = useState(0);
  const errors = _.get(props.errors, `${props.name}`);

  useEffect(() => {
    if (props.resetTrigger !== undefined) {
      setText('');
      setCharNum(0);
      props.setValueHandler && props.setValueHandler(props.name, '');
    }
  }, [props.resetTrigger]);

  useEffect(() => {
    if (props.getValues !== undefined) {
      setCharNum(props.getValues(props.name).length);
    }
  }, []);

  function handleChange(event: any) {
    props.handleChange && props.handleChange(event);
    props.resetTrigger !== undefined && setText(event.target.value);
    setCharNum(event.target.value.length);
  }

  return (
    <React.Fragment>
      <label
        htmlFor={`${props.id}TextArea`}
        id={`${props.id}Label`}
        className={_.get(props.errors, `${props.name}`) && 'form-error__label'}
      >
        <Text field={props.title} />
      </label>
      <textarea
        id={`${props.id}TextArea`}
        aria-labelledby={`${props.id}Label`}
        name={props.name}
        className={`${_.get(props.errors, `${props?.name}`) && 'form-error__field'}`}
        maxLength={props.maxLength}
        disabled={props.isDisabled}
        onChange={handleChange}
        {...(props.register &&
          props.register(props.name, { required: props.isRequired, onChange: handleChange }))}
        {...(props.resetTrigger !== undefined && {
          value: text
        })}
        aria-describedby={`${props.id}ErrorDesc`}
      />
      {!props.hideLimit && (
        <div className="form-help-text-default">
          <div className="help-text">
            <Text field={props.textAreaLimit} />
          </div>
          <div className="form-help-text-word-count">
            {`${charNum.toString()} ${props.limitCountText}`}
          </div>
        </div>
      )}
      {!props.hideError && (
        <div className="form__fieldNotificationHolder">
          {errors && (
            <span
              className="form-error__sub-text"
              aria-live="assertive"
              id={`${props.id}ErrorDesc`}
            >
              {GetErrorMessage(errors.type as string, errors.message as string)}
              <input type="hidden" className="thisFieldHasErrors" value={props.cardName} />
            </span>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default FormInputMultiLineText;
