import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars-2';
import i18n from 'i18next';

import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

import { useHBTFormContext } from 'Feature/Claims/components/HBTFormContext';
import FormTextArea from 'Components/Inputs/FormTextArea';
import FormInputWrapper from 'Components/Inputs/FormInputWrapper';
import { Button } from 'Components/Common/Button';
import { AttachmentsView } from 'Components/Common/AttachmentsView';
import FormDropdown from 'Components/Inputs/FormDropdown';
import FormRadioGroup from 'Components/Inputs/FormRadioGroup';

import { config } from '../../../../../../config';
import useDeclineClaimFunctions from './useDeclineClaim';
import { AllowedFileTypes, DeclineClaimProps } from './types';
import styles from './styles.module.scss';

export const DeclineClaimForm: React.FC<DeclineClaimProps> = ({
  onCancelCallback,
  onSuccessCallback,
  onErrorCallback,
  fields
}: DeclineClaimProps) => {
  const { claimData, loadingStatus } = useHBTFormContext();
  const { hookForm, handleSubmit, onSubmitErrors, displayedRequestTemplate, cancelFlyout } =
    useDeclineClaimFunctions(
      fields,
      onSuccessCallback,
      onErrorCallback,
      onCancelCallback,
      claimData?.detail?.claimTypeCode,
      claimData?.cmhcLoanAccountNumber
    );

  return (
    <Scrollbars>
      <div className={styles.declineClaimFormHeader} data-testid={`declineClaimForm`}>
        <div className={`${styles.textHeader}`}>
          <Text tag="h2" field={fields.formTitle} />
        </div>
        <div className={styles.closeButton}>
          <button
            type="button"
            data-testid="addDclineClaimCloseButton"
            onClick={cancelFlyout}
            aria-label={i18n.t('Accessibility-Close-Button') ?? ''}
          >
            <span className="material-icons align-self-center">close</span>
          </button>
        </div>
      </div>
      <div className={`${styles.declineClaimFormBody}`}>
        <div className={`${styles.textStyle}`}>
          <Text tag="p" field={fields.content} />
        </div>
        <FormProvider {...hookForm}>
          <form
            className={styles.declineForm}
            onSubmit={hookForm.handleSubmit(handleSubmit, onSubmitErrors)}
            id="declineClaimForm"
          >
            <div className={styles.templateWrapper}>
              <div className={styles.languageCodeWrapper}>
                <FormRadioGroup
                  name="languageCode"
                  className=""
                  label={fields.languageLabel}
                  radioLabels={fields.languageOptions}
                  defaultSelection={claimData?.lender?.correspondenceLanguageCode}
                  setAsNumber={true}
                />
              </div>
              <FormDropdown
                name="declineReasonCode"
                label={fields.categoryLabel}
                options={fields.categoryList?.fields?.listItems}
              />

              <div className={styles.notificationTemplate}>
                <FormTextArea
                  name="remarkText"
                  label={fields.note}
                  className={styles.declineNotificationTemplate}
                  placeholder={fields.notePlaceholder.value}
                  charLimit={Number(fields.limitNumber.value)}
                  textAreaHelperText={fields.characterLimit.value}
                />
                <FormInputWrapper
                  name="notificationTemplateLabel"
                  label={fields.notificationTemplateLabel}
                />
                <RichText
                  field={displayedRequestTemplate.field}
                  className={styles.notificationTemplateWrapper}
                  name="declineNotificationTemplate"
                />
              </div>
            </div>
            {React.createElement(AttachmentsView, {
              isOptional: true,
              registeredVal: 'attachments',
              fields: fields.attachmentsView.fields,
              endpoint: config.claimApi.urls.upload,
              allowedFileTypes: AllowedFileTypes
            })}

            <div className={`${styles.twoColumns} ${styles.formButtonsWrapper}`}>
              <div>
                <Button
                  name="submitButton"
                  responsive
                  loading
                  type="submit"
                  ariaText={{ value: 'Submit' }}
                  text={fields.submitBtn}
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                  icon={
                    loadingStatus?.isLoading && loadingStatus?.isFlyout
                      ? () => (
                          <span className={styles.saveIconWrapper}>
                            <span className={`material-icons align-self-center`}>loop</span>
                          </span>
                        )
                      : undefined
                  }
                />
              </div>
              <div>
                <Button
                  secondaryButton
                  responsive
                  onClick={cancelFlyout}
                  ariaText={{}}
                  name={`CancelButton`}
                  text={fields.cancelBtn}
                  type="button"
                  disabled={loadingStatus?.isLoading && loadingStatus?.isFlyout}
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </Scrollbars>
  );
};
