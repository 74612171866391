/* eslint-disable */
import { ICard } from './Card.types';

interface ValuesData {
  defaultStatus: number;
  homeownerLoan: boolean;
  validLoan: boolean;
  submittedDefaultStage: number;
  currentAmortizationPeriod: string;
  newAmortizationPeriodSubmitted: string;
  newAmortizationPeriodPurposed: string;
}

// perform calculations on initial mount for prepopulating data
export const prepopulateCalculations = (allCardValues: Array<object>) => {
  const totalSumsArrayCopy: number[] = [];
  for (let i = 0; i < allCardValues.length; i += 1) {
    // loop through array of card instances
    const cardInstanceArray = Object.values(allCardValues[i]);
    let cardInstanceTotal = 0;
    cardInstanceArray.forEach((value) => {
      if (typeof value === 'number') {
        cardInstanceTotal += value;
      }
    });
    totalSumsArrayCopy[i] = cardInstanceTotal;
  }
  return totalSumsArrayCopy;
};

const setValueData = (
  setValue: ICard['setValueHandler'],
  valuesData: ValuesData,
  register: ICard['register']
) => {
  if (register != null && setValue != null) {
    register('managementInformation.submittedDefaultStage');
    setValue('managementInformation.submittedDefaultStage', valuesData.submittedDefaultStage);

    register('other.defaultStatus');
    setValue('other.defaultStatus', valuesData.defaultStatus);

    register('other.homeownerLoan');
    setValue('other.homeownerLoan', valuesData.homeownerLoan);

    register('other.validLoan');
    setValue('other.validLoan', valuesData.validLoan);

    register('detail.firstLoan.currentAmortizationPeriod');
    setValue('detail.firstLoan.currentAmortizationPeriod', valuesData.currentAmortizationPeriod);

    register('managementInformation.submittedStageTwo.newAmortizationPeriod');
    setValue(
      'managementInformation.submittedStageTwo.newAmortizationPeriod',
      valuesData.newAmortizationPeriodSubmitted ?? '0000'
    );

    register('managementInformation.proposedStageTwo.newAmortizationPeriod');
    setValue(
      'managementInformation.proposedStageTwo.newAmortizationPeriod',
      valuesData.newAmortizationPeriodPurposed
    );
  }
};

// delete cards logic
export const deleteCardRepopulateValues = (
  getValues: ICard['getValues'],
  setValueHandler: ICard['setValueHandler'],
  watch: ICard['watch'],
  reset: ICard['reset'],
  index: number,
  lastIndexPreDelete: number,
  name: string,
  register?: ICard['register']
) => {
  if (getValues && watch && reset && setValueHandler) {
    const valuesData = {
      defaultStatus: watch('other.defaultStatus'),
      homeownerLoan: watch('other.homeownerLoan'),
      validLoan: watch('other.validLoan'),
      submittedDefaultStage: watch('managementInformation.submittedDefaultStage'),
      currentAmortizationPeriod: watch('detail.firstLoan.currentAmortizationPeriod'),
      newAmortizationPeriodSubmitted: watch(
        'managementInformation.submittedStageTwo.newAmortizationPeriod'
      ),
      newAmortizationPeriodPurposed: watch(
        'managementInformation.proposedStageTwo.newAmortizationPeriod'
      )
    };
    // save a copy of asset cards
    const allCardValues = watch(name) || [];
    // clears all asset cards
    reset({ ...getValues(), [`${name}`]: [] });

    if (Array.isArray(allCardValues)) {
      // type check
      for (let i = 0; i < index; i += 1) {
        // re-populate cards before deleted index
        setValueHandler(`${name}[${i}]`, allCardValues[i]);
      }
      for (let j = index; j < lastIndexPreDelete; j += 1) {
        // Reset values after index
        setValueHandler(`${name}[${j}]`, allCardValues[j + 1]);
      }
    }

    // Set valuesData again after card deletion. Otherwise, these values are cleared
    setValueData(setValueHandler, valuesData, register);
  }
};

// Scroll into view after deleting a card instance
export const scrollIntoView = (elementId: string) => {
  const titleId = document.getElementById(elementId);

  if (titleId != null) {
    titleId.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

// Round accurately, avoiding JS inherent floating point math rounding errors
export const roundAccurately = (number: number, decimalPlaces: number): number => {
  // @ts-ignore
  return Number(`${Math.round(`${number}e${decimalPlaces}`)}e-${decimalPlaces}`);
};

export const setFixedDecimalPercent = (number: number, decimalPlaces: number): string => {
  return `${number.toFixed(decimalPlaces)}%`;
};
