import React, { useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import { isServer } from '@sitecore-jss/sitecore-jss/utils';
import { Prompt } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { withFeature } from 'flagged';
import { joiResolver } from '@hookform/resolvers/joi';
import stickybits from 'stickybits';

import { defaultsPortal } from '@hobt/form-schema-validators';
import { StringTemplate } from '@hobt/data-vis-components';
import { UserRole, Module } from '@hobt/constants';

import {
  NotificationCard,
  NotificationCardProps
} from 'Feature/CommonComponents/ContentComponents';
import { CardType } from 'Feature/CommonComponents/Enums';
import { LoaderAnimation, Button, ButtonType } from 'Feature/CommonComponents/UserControls';
import { StickyFooter } from 'Components/PageComponents/StickyFooter';
import { isUserInRoles } from 'Components/Common/UserHelpers/CheckUserRole';

import { ICard } from 'Feature/DefaultSubmissionForm/components/Card.types';
import { ProgressBar, getProgressBarData } from 'Feature/CommonComponents/ProgressBarComponents';
import {
  getLenderDetailsProps,
  LenderDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/LenderDetails';
import {
  getLoanDetailsProps,
  LoanDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/LoanDetails';
import {
  Borrower,
  getBorrowerDetailsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/Borrower';
import {
  Assets,
  getAssetsDetailsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/Assets';
import {
  getLiabilitiesDetailsProps,
  Liabilities
} from 'Feature/DefaultSubmissionForm/components/Cards/Liabilities';
import {
  getMortgageDetailsProps,
  MortgageObligations
} from 'Feature/DefaultSubmissionForm/components/Cards/MortgageObligations';
import {
  GdsTdsCalculations,
  getGdsTdsCalculationsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/GdsTdsCalculations';
import {
  getfinancialObligationsProps,
  OtherFinancialObligations
} from 'Feature/DefaultSubmissionForm/components/Cards/OtherFinancialObligations';
import {
  getReasonsDefaultManagementProps,
  ReasonsDefaultManagement
} from 'Feature/DefaultSubmissionForm/components/Cards/ReasonsDefaultManagement';
import {
  DefaultManagementTools,
  getDefaultManagementToolsProps
} from 'Feature/DefaultSubmissionForm/components/Cards/DefaultManagementTools';

import { TargetSection } from 'Feature/DefaultSubmissionForm/models/typeCode.types';
import {
  getLenderAcknowledgmentProps,
  LenderAcknowledgment
} from 'Feature/DefaultSubmissionForm/components/Cards/LenderAcknowledgment';
import {
  getMonitoringStrategyProps,
  MonitoringStrategy
} from 'Feature/DefaultSubmissionForm/components/Cards/MonitoringStrategy';
import {
  getDefaultHistoryProps,
  HistoryDetails
} from 'Feature/DefaultSubmissionForm/components/Cards/History';
import {
  AttachmentForm,
  getAttachmentFormProps
} from 'Feature/DefaultSubmissionForm/components/Cards/AttachmentForm';
import { CardCountsObject } from 'Feature/DefaultsInventory/models/DefaultInventoryDetails';
import GetErrorCount from 'Feature/DefaultSubmissionForm/components/TopErrorMessages';
import { FeatureFlags } from 'Feature/Enums/FeatureFlag.enum';

// Analytics Service
import useCustomEvent from 'Foundation/Analytics/useCustomEvent';
import { useAppInsightsContext } from 'Foundation/Analytics/AppInsightsContext';
import { removeEmptyFields } from 'Constants/Utils/RemoveEmptyFields';
import { ConcatenateAmortizationValuesToString } from 'Components/Common/Helpers';
import { mapDefaultDetailsSubmissionFields } from '../DefaultDetailSubmissionFormMapings';
import { DefaultDetailSubmissionFormProps } from './DefaultDetailSubmissionForm.types';

import styles from './styles.module.scss';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { HbtSitecoreContextType } from 'Foundation/HydrateSitecoreContext';

const DefaultDetailSubmissionForm: React.FC<DefaultDetailSubmissionFormProps> = ({
  detailsData,
  watchLoanValidation,
  setDisableCardBtns,
  defaultSubmissionFormProps,
  onSubmitCallback,
  activeTab,
  disableCalendar
}) => {
  const errorCardId = 'DefaultInventoryErrorForm';

  const [borrowerTotalMonthlyGrossIncome, setBorrowerTotalMonthlyGrossIncome] = useState(0);
  const [totalMonthlyHouseholdHousingExpenses, setTotalMonthlyHouseholdHousingExpenses] =
    useState(0);
  const [totalMonthlyHouseholdUnsecuredDebt, setTotalMonthlyHouseholdUnsecuredDebt] = useState(0);
  const [totalAssetsArray, setTotalAssetsArray] = useState([0]);
  const [totalLiabilitiesArray, setTotalLiabilitiesArray] = useState([0]);
  const [cumulativeTotalAssets, setCumulativeTotalAssets] = useState(0);
  const [cumulativeTotalLiabilities, setCumulativeTotalLiabilities] = useState(0);

  const [totalFormErrorCount, setTotalFormErrorCount] = useState(0);
  const [formHasError, setFormHasError] = useState(false);
  const [formErrorMessages, setFormErrorMessages] = useState<string[]>([]);
  const [bypassIsDirty, setBypassIsDirty] = useState<boolean>(false);
  const [isAttachmentDirty, setIsAttachmentDirty] = useState<boolean>(false);

  // manually setting own isDirty check for card deletions
  const [cardDeleted, setCardDeleted] = useState<boolean>(false);

  const [isAttachmentUploading, setIsAttachmentUploading] = useState<boolean>(false);

  // pre-populated data cardCounts:
  const [cardCounts, setCardCounts] = useState<CardCountsObject>({});

  // manually setting own isDirty check for stage three tool deletions
  const [stageThreeToolDeleted, setStageThreeToolDeleted] = useState<boolean>(false);

  // Creating card referance of use ref and send to progress bar
  const cardReference = useRef(
    Array.from({ length: 20 }, () => React.createRef() as React.MutableRefObject<HTMLSpanElement>)
  );

  const validationErrNotification: NotificationCardProps = {
    title: StringTemplate(
      i18n.t('DefaultsInventoryDetails-NotificationCard-ValidationErrStrTemplate'),
      '{{}}',
      '1'
    ),
    message: [i18n.t('DefaultsInventoryDetails-NotificationCard-ValidationErrorMessage')],
    notificationType: CardType.VERTICAL_WARNING,
    formWarningIcon: defaultSubmissionFormProps.fields.formWarningIcon
  };

  // App Insights
  const reactPlugin = useAppInsightsContext();
  const trackDefaultSubmission = useCustomEvent(reactPlugin, 'Default Detail Form Submission', {});

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
    getValues,
    reset,
    formState
  } = useForm({
    resolver: joiResolver(defaultsPortal),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldFocusError: false
  });

  const commonCardProps: ICard = {
    register,
    errors,
    setValueHandler: setValue,
    control,
    watch,
    getValues,
    reset,
    setCardDeleted,
    setStageThreeToolDeleted
  };

  // Form Handling
  const onSubmit = (formData: any) => {
    const updateFormData = formData;

    updateFormData.managementInformation.submittedStageTwo.newAmortizationPeriod =
      ConcatenateAmortizationValuesToString(
        getValues('managementInformation.submittedStageTwo.newAmortizationPeriodYears'),
        getValues('managementInformation.submittedStageTwo.newAmortizationPeriodMonths')
      );

    updateFormData.detail.firstLoan.currentAmortizationPeriod =
      ConcatenateAmortizationValuesToString(
        getValues('detail.firstLoan.currentAmortizationPeriodYears'),
        getValues('detail.firstLoan.currentAmortizationPeriodMonths')
      );

    const strippedValues = removeEmptyFields(updateFormData);

    setStageThreeToolDeleted(false);
    setFormHasError(false);
    onSubmitCallback(false, strippedValues);
  };

  const onError = (errs: any) => {
    trackDefaultSubmission({ frontendFormErrors: errs });
    setFormHasError(true);
    setDisableCardBtns(true);
    setTimeout(() => {
      setTotalFormErrorCount(document.querySelectorAll('[class^=thisFieldHasError]').length);
      setFormErrorMessages(GetErrorCount);

      const errorCardElement = document.getElementById(errorCardId);
      if (errorCardElement != null) {
        errorCardElement.focus();
      }
    }, 0);
    console.log('errs', errs);
    console.log('get default Values', getValues());
  };

  useEffect(() => {
    return () => {
      if (isServer() === false) {
        // clean up onbeforeunload, on component unmount
        window.onbeforeunload = null;
      }
    };
  }, []);

  // return number of pre-populated cards, if exists
  const prepopulatedLength = (name: string): number | undefined => {
    return detailsData[name] && detailsData[name].length;
  };

  const prepopToolsLength = () => {
    const stageThreeDataObj = detailsData.managementInformation.submittedStageThree;
    return (
      stageThreeDataObj.combinationFlag &&
      stageThreeDataObj.toolDetail &&
      stageThreeDataObj.toolDetail.length
    );
  };

  const setValueData = () => {
    setStageThreeToolDeleted(false); // disable save and cancel btns

    // Manually register unbound fields
    register('other.defaultStatus');
    register('other.homeownerLoan');
    register('other.validLoan');

    // setting these values to 0000 as these are just needed for schema validation
    register('detail.firstLoan.currentAmortizationPeriod');
    setValue('detail.firstLoan.currentAmortizationPeriod', '0000');

    register('managementInformation.submittedDefaultStage');
    setValue('managementInformation.submittedDefaultStage', '0000');

    register('managementInformation.submittedStageTwo.newAmortizationPeriod');
    setValue('managementInformation.submittedStageTwo.newAmortizationPeriod', '0000');

    if (detailsData.other.defaultStatusComment !== undefined) {
      register('other.defaultStatusComment');
    }

    if (detailsData.other.defaultStatusReason !== undefined) {
      register('other.defaultStatusReason');
    }
  };

  useEffect(() => {
    // functions to set pre-populated data card counts:
    setCardCounts({
      asset: prepopulatedLength(`asset`),
      borrower: prepopulatedLength(`borrower`),
      liability: prepopulatedLength(`liability`),
      mortgageObligation: prepopulatedLength(`mortgageObligation`),
      financialObligation: prepopulatedLength(`financialObligation`),
      stageThreeTools: prepopToolsLength()
    });

    const mappedData = mapDefaultDetailsSubmissionFields(detailsData);

    reset(mappedData);
    setValueData();
  }, [detailsData]);

  const validLoanWatch: boolean = watch('other.validLoan');

  useEffect(() => {
    if (validLoanWatch != null) {
      watchLoanValidation(validLoanWatch);
    }
  }, [validLoanWatch]);

  // scroll fix for IE:
  useEffect(() => {
    stickybits('#progressBarDetail', {
      stickyClass: 'IEsticky',
      stuckClass: 'IEstuck'
    });
  });

  const formErrorsLength = Object.keys(formState.errors).length;
  const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
  // isDirty check
  useEffect(() => {
    if (isServer() === false) {
      // onbeforeunload should trigger on back, forward, refresh, and link button clicks
      window.onbeforeunload = () => {
        // trigger browser warning
        if ((isDirtyAlt || isAttachmentUploading) && bypassIsDirty === false) {
          // returning anything, will trigger the prompt,
          // return string only read on IE:
          return i18n.t('DefaultSubmission-IsDirty');
        }

        return null;
      };
    }
    setDisableCardBtns(isDirtyAlt || formErrorsLength > 0 || formHasError);
  }, [isDirtyAlt, formErrorsLength, bypassIsDirty]);

  const sitecoreContextFactory = useSitecoreContext();
  const sitecoreContext = sitecoreContextFactory?.sitecoreContext as HbtSitecoreContextType;

  const moduleRoleMapping = sitecoreContext && sitecoreContext?.user?.moduleRoleMapping;
  const isReadOnlyUser: boolean = isUserInRoles(
    Module.Default,
    [UserRole.ReadOnly],
    moduleRoleMapping
  );

  if (Object.keys(cardCounts).length > 0) {
    return (
      <>
        {/* React Router prompt on route navigations */}
        <Prompt
          when={isDirtyAlt || isAttachmentUploading}
          message={i18n.t('DefaultSubmission-IsDirty')}
        />
        <form
          id="defaultInventoryDetailsForm"
          name="default-inventory-details-form"
          className="form default-submission-form"
          onSubmit={handleSubmit(onSubmit, onError)}
          noValidate
        >
          <div className={styles.layoutContainer}>
            <div>
              {/* Loan validation warning */}
              {!validLoanWatch && <NotificationCard {...validationErrNotification} />}

              {/* Card level error */}
              {formHasError && formErrorMessages.length >= 0 && (
                <NotificationCard
                  notificationType={CardType.VERTICAL_ERROR}
                  title={i18n
                    .t('DefaultSubmission-Card-TopErrorMessage')
                    .replace('{count}', totalFormErrorCount.toString())}
                  message={formErrorMessages}
                  formWarningIcon={defaultSubmissionFormProps.fields.formErrorIcon}
                  id={errorCardId}
                />
              )}

              {/* Cards */}
              <span ref={cardReference.current[0]} />
              <LenderDetails
                {...getLenderDetailsProps()}
                {...commonCardProps}
                id="lenderDetails"
                activeTab={activeTab}
              />

              <span ref={cardReference.current[1]} />
              <LoanDetails
                {...getLoanDetailsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="loanDetails"
                disableCalendar={disableCalendar}
              />

              <span ref={cardReference.current[2]} />
              <Borrower
                {...getBorrowerDetailsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                cardId="borrowerDetails"
                cardCount={cardCounts.borrower}
                borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                setBorrowerTotalMonthlyGrossIncome={setBorrowerTotalMonthlyGrossIncome}
              />

              <span ref={cardReference.current[3]} />
              <Assets
                {...getAssetsDetailsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                cardId="assets"
                cardCount={cardCounts.asset}
                totalAssetsArray={totalAssetsArray}
                setTotalAssetsArray={setTotalAssetsArray}
                cumulativeTotalAssets={cumulativeTotalAssets}
                setCumulativeTotalAssets={setCumulativeTotalAssets}
              />

              <span ref={cardReference.current[4]} />
              <Liabilities
                {...getLiabilitiesDetailsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="liabilities"
                cardCount={cardCounts.liability}
                totalLiabilitiesArray={totalLiabilitiesArray}
                setTotalLiabilitiesArray={setTotalLiabilitiesArray}
                cumulativeTotalLiabilities={cumulativeTotalLiabilities}
                setCumulativeTotalLiabilities={setCumulativeTotalLiabilities}
                cumulativeTotalAssets={cumulativeTotalAssets}
              />

              <span ref={cardReference.current[5]} />
              <MortgageObligations
                {...getMortgageDetailsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="mortgageObligations"
                cardCount={cardCounts.mortgageObligation}
              />

              <span ref={cardReference.current[6]} />
              <GdsTdsCalculations
                {...getGdsTdsCalculationsProps()}
                {...commonCardProps}
                id="gdsTdsCalulations"
                borrowerTotalMonthlyGrossIncome={borrowerTotalMonthlyGrossIncome}
                totalMonthlyHouseholdHousingExpenses={totalMonthlyHouseholdHousingExpenses}
                setTotalMonthlyHouseholdHousingExpenses={setTotalMonthlyHouseholdHousingExpenses}
                totalMonthlyHouseholdUnsecuredDebt={totalMonthlyHouseholdUnsecuredDebt}
                setTotalMonthlyHouseholdUnsecuredDebt={setTotalMonthlyHouseholdUnsecuredDebt}
              />

              <span ref={cardReference.current[7]} />
              <OtherFinancialObligations
                {...getfinancialObligationsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="otherFinancialObligations"
                cardCount={cardCounts.financialObligation}
              />

              <span ref={cardReference.current[8]} />
              <ReasonsDefaultManagement
                {...getReasonsDefaultManagementProps()}
                {...commonCardProps}
                id="reasonsDefaultManagement"
                maxLength={Number(i18n.t('Globals-MultilineText-MaxLength'))}
                disableCalendar={disableCalendar}
              />

              <span ref={cardReference.current[9]} />
              <DefaultManagementTools
                {...getDefaultManagementToolsProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="defaultManagementToolsInventoryDetailsTab"
                title={{
                  field: {
                    value: i18n.t('DefaultSubmission-Card-DefaultManagementToolsHeading')
                  }
                }}
                cardCount={cardCounts.stageThreeTools}
                targetSection={TargetSection.DEFAULTSUBMISSION}
                linedCardProps={{
                  id: 'defaultManagementToolsInventoryDetailsTab',
                  testId: 'defaultManagementToolsInventoryDetailsTab',
                  linePosition: 'vertical',
                  lineColor: 'grey'
                }}
                disableCalendar={disableCalendar}
              />

              <span ref={cardReference.current[10]} />
              <LenderAcknowledgment
                {...getLenderAcknowledgmentProps()}
                {...commonCardProps}
                id="lenderAcknowledgement"
              />

              <span ref={cardReference.current[11]} />
              <MonitoringStrategy
                {...getMonitoringStrategyProps()}
                {...commonCardProps}
                id="monitoringStrategy"
              />

              <span ref={cardReference.current[12]} />
              <HistoryDetails
                {...getDefaultHistoryProps()}
                {...commonCardProps}
                id="defaultHistory"
              />

              <span ref={cardReference.current[13]} />
              <AttachmentForm
                {...getAttachmentFormProps(defaultSubmissionFormProps)}
                {...commonCardProps}
                id="attachments"
                numLoadedAttachments={detailsData.attachment ? detailsData.attachment.length : 0}
                indicateAttachmentUploadStatus={(status: boolean) => {
                  setIsAttachmentUploading(status);
                }}
                setAttachmentDirtyCallback={setIsAttachmentDirty}
                isAttachmentDirty={isAttachmentDirty}
                isInternalSite={FeatureFlags.INTERNAL}
                onDownloadCallback={() => {
                  setBypassIsDirty(true);
                }}
                downloadMode={
                  detailsData.attachment != null && detailsData.attachment?.length !== 0
                }
                attachments={detailsData.attachment}
              />
            </div>
            <aside className={styles.showForDesktopOnly}>
              <div className={`form-progress__sticky`} id="progressBarDetail">
                {isServer() === false && activeTab === TargetSection.DEFAULTSUBMISSION && (
                  <ProgressBar
                    {...getProgressBarData(cardReference.current)}
                    isDisabledButton={isReadOnlyUser}
                    onCancelHandler={() => {
                      setTimeout(() => {
                        reset();
                        setValueData();
                      }, 0);
                    }}
                    onSubmitHandler={() => handleSubmit(onSubmit, onError)}
                    submitButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarSaveButton')
                    }}
                    cancelButtonText={{
                      value: i18n.t('DefaultSubmission-Card-ProgressBarDiscardChangesButton')
                    }}
                  />
                )}
              </div>
            </aside>
          </div>
          <StickyFooter>
            <div className={`${styles.hideForDesktop} ${styles.stickyFooter}`}>
              <Button
                id="buttonSubmit"
                name="primary-button"
                ariaText={i18n.t('DefaultSubmission-Card-ProgressBarSaveButton')}
                disabled={isReadOnlyUser}
                buttonType={ButtonType.PRIMARY}
                displayText={i18n.t('DefaultSubmission-Card-ProgressBarSaveButton')}
              />
              <Button
                id="buttonSaveDraft"
                name="secondary-button"
                ariaText={i18n.t('DefaultSubmission-Card-ProgressBarDiscardChangesButton')}
                displayText={i18n.t('DefaultSubmission-Card-ProgressBarDiscardChangesButton')}
                disabled={isReadOnlyUser}
                onClick={() => {
                  reset();
                  setValueData();
                }}
                buttonType={ButtonType.SECONDARY}
              />
            </div>
          </StickyFooter>
        </form>
      </>
    );
  }
  return <LoaderAnimation />;
};

export default withFeature(FeatureFlags.INTERNAL)(DefaultDetailSubmissionForm);
