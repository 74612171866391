import * as React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import { defaultEmptyString } from 'Components/Common/Api/utils/HandleEmpty';
import { NotificationListProps } from 'Feature/Header/components/Notifications/NotificationList/types';
import {
  APPROVAL_NOTIFICATION_SCENARIOS,
  ARREARS_SUBMISSION_SCENARIOS,
  CLAIM_SUBMISSION_SCENARIOS,
  CONTACT_UPDATE_SCENARIOS,
  CRM_PAYEE_NOTIFICATION_SCENARIOS,
  NotificationListItem,
  NotificationStatus
} from 'Feature/Header/components/Notifications/types';

import styles from './styles.module.scss';

export const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  onItemClick,
  selectedNotificationId,
  fields
}: NotificationListProps) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onItemClick(e);
    }
    e.stopPropagation();
  };

  return (
    <ul className={styles.notificationList}>
      {notifications?.map(
        ({ date, id, lender, status, scenarioId, title }: NotificationListItem) => (
          <li
            key={id}
            className={`${
              status === NotificationStatus.UNREAD && selectedNotificationId !== id
                ? styles.isUnread
                : ''
            } ${selectedNotificationId === id ? styles.selectedNotification : ''}`.trim()}
          >
            <div
              tabIndex={0}
              data-notificationId={id}
              role="button"
              onClick={onItemClick}
              onKeyDown={handleKeyDown}
            >
              {[
                ...APPROVAL_NOTIFICATION_SCENARIOS,
                ...ARREARS_SUBMISSION_SCENARIOS,
                ...CRM_PAYEE_NOTIFICATION_SCENARIOS,
                ...CONTACT_UPDATE_SCENARIOS,
                ...CLAIM_SUBMISSION_SCENARIOS
              ].includes(scenarioId ? parseInt(scenarioId, 10) : 0) ? (
                <RichText tag="p" field={{ value: title }} />
              ) : (
                <span>
                  <p>{title}</p>
                  <span>
                    <Text field={fields.lenderNumber} /> {lender ?? defaultEmptyString}
                  </span>
                </span>
              )}
              <time dateTime={date}>{date}</time>
            </div>
          </li>
        )
      )}
      {notifications?.length > 0 && (
        <li className={styles.endOfPageText}>
          <Text field={fields.endOfPage} />
        </li>
      )}
    </ul>
  );
};
