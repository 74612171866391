import {
  DefaultDetailsAsset,
  DefaultDetailsBorrower,
  DefaultDetailsLiability,
  DefaultDetailsResponseData
} from 'Foundation/Api/models';

const mappedDataAssets = (detailsData: DefaultDetailsResponseData) => {
  return detailsData.asset.map((asset: DefaultDetailsAsset) => {
    return {
      ...asset,
      cashEquivalents: asset.cashEquivalents ?? '',
      other: asset.other ?? '',
      owner: asset.owner?.toString() ?? '',
      rentalProperty: asset.rentalProperty ?? ''
    };
  });
};

const mappedDataBorrowers = (detailsData: DefaultDetailsResponseData) => {
  return detailsData.borrower.map((borrower: DefaultDetailsBorrower) => {
    return {
      ...borrower,
      borrowerTypeID: borrower.borrowerTypeID?.toString() ?? '',
      creditObtained: borrower.creditObtained?.toString() ?? '',
      employment: {
        ...borrower.employment,
        detailedEmploymentProspects: borrower.employment?.detailedEmploymentProspects ?? ''
      },
      mailingAddress: {
        ...borrower.mailingAddress,
        municipalityName: borrower.mailingAddress?.municipalityName ?? '',
        postalCode: borrower.mailingAddress?.postalCode ?? '',
        provinceCode: borrower.mailingAddress?.provinceCode ?? '',
        streetDirection: borrower.mailingAddress?.streetDirection ?? '',
        streetName: borrower.mailingAddress?.streetName ?? '',
        streetNumber: borrower.mailingAddress?.streetNumber ?? '',
        streetType: borrower.mailingAddress?.streetType ?? '',
        unitNumber: borrower.mailingAddress?.unitNumber ?? ''
      },
      submittedMonthlyIncomeSource: {
        ...borrower.submittedMonthlyIncomeSource,
        employmentInsurance: borrower.submittedMonthlyIncomeSource?.employmentInsurance ?? '',
        investmentIncome: borrower.submittedMonthlyIncomeSource?.investmentIncome ?? '',
        other: borrower.submittedMonthlyIncomeSource?.other ?? ''
      }
    };
  });
};

const mappedDataLiabilities = (detailsData: DefaultDetailsResponseData) => {
  return detailsData.liability.map((liability: DefaultDetailsLiability) => {
    return {
      ...liability,
      owner: liability.owner?.toString() ?? ''
    };
  });
};

export const mapDefaultDetailsSubmissionFields = (detailsData: DefaultDetailsResponseData) => {
  return {
    asset: mappedDataAssets(detailsData),
    borrower: mappedDataBorrowers(detailsData),
    detail: {
      ...detailsData.detail,
      firstLoan: {
        ...detailsData.detail.firstLoan,
        registeredMortgageHolder: detailsData.detail.firstLoan.registeredMortgageHolder ?? '',
        originalAmortizationPeriodMonths: '',
        originalAmortizationPeriodYears: ''
      },
      insuredPropertyStreetDirection: detailsData.detail.insuredPropertyStreetDirection ?? '',
      insuredPropertyUnitNumber: detailsData.detail.insuredPropertyUnitNumber ?? '',
      secondLoan: {
        registeredMortgageHolder: detailsData.detail.secondLoan.registeredMortgageHolder ?? '',
        requiredMonthlyPaymentPrincipalInterest:
          detailsData.detail.secondLoan.requiredMonthlyPaymentPrincipalInterest ?? '',
        unpaidBalance: detailsData.detail.secondLoan.unpaidBalance ?? ''
      },
      updatedCondominiumFee: detailsData.detail.updatedCondominiumFee?.toString() ?? '',
      updatedPropertyInsurance: detailsData.detail.updatedPropertyInsurance?.toString() ?? '',
      updatedPropertyTax: detailsData.detail.updatedPropertyTax?.toString() ?? '',
      updatedUtilities: detailsData.detail.updatedUtilities?.toString() ?? ''
    },
    financialObligation: detailsData.financialObligation,
    grossDebtServiceTotalDebtService: {
      ...detailsData.grossDebtServiceTotalDebtService,
      submittedHousingExpense: {
        ...detailsData.grossDebtServiceTotalDebtService.submittedHousingExpense,
        fiftyPercentCondoFee:
          detailsData.grossDebtServiceTotalDebtService.submittedHousingExpense
            .fiftyPercentCondoFee ?? ''
      },
      submittedOtherMonthlyExpense: {
        ...detailsData.grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense,
        other: detailsData.grossDebtServiceTotalDebtService.submittedOtherMonthlyExpense.other ?? ''
      }
    },
    history: {
      defaultHistory: detailsData.history.defaultHistory?.toString() ?? '',
      description: detailsData.history.description ?? ''
    },
    lender: {
      ...detailsData.lender,
      contactName: detailsData.lender.contactName ?? '',
      contactPhoneNumber: detailsData.lender.contactPhoneNumber ?? '',
      contactPhoneNumberExtension: detailsData.lender.contactPhoneNumberExtension ?? ''
    },
    liability: mappedDataLiabilities(detailsData),
    managementInformation: {
      ...detailsData.managementInformation,
      lenderAcknowledgementAdditionalDetails:
        detailsData.managementInformation.lenderAcknowledgementAdditionalDetails ?? '',
      preApprovalRequired: detailsData.managementInformation.preApprovalRequired.toString(),
      submittedDefaultStage: detailsData.managementInformation.submittedDefaultStage ?? '',
      submittedStageOne: {
        ...detailsData.managementInformation.submittedStageOne,
        conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage:
          detailsData.managementInformation.submittedStageOne
            ?.conversionOfRepaymentArrangementToAFixedRateEqualPaymentMortgage ?? '',
        mortgagePaymentDeferral:
          detailsData.managementInformation.submittedStageOne?.mortgagePaymentDeferral ?? '',
        prepayAndReBorrow:
          detailsData.managementInformation.submittedStageOne?.prepayAndReBorrow ?? ''
      },
      submittedStageTwo: {
        ...detailsData.managementInformation.submittedStageTwo,
        capitalization: detailsData.managementInformation.submittedStageTwo?.capitalization ?? '',
        extendedMortgagePaymentDeferral:
          detailsData.managementInformation.submittedStageTwo?.extendedMortgagePaymentDeferral ??
          '',
        extensionOfAmortizationPeriod:
          detailsData.managementInformation.submittedStageTwo?.extensionOfAmortizationPeriod ?? '',
        interestOnlyPayment:
          detailsData.managementInformation.submittedStageTwo?.interestOnlyPayment ?? '',
        newAmortizationPeriod:
          detailsData.managementInformation.submittedStageTwo?.newAmortizationPeriod ?? '0000',
        specialPaymentArrangement:
          detailsData.managementInformation.submittedStageTwo?.specialPaymentArrangement ?? ''
      },
      submittedStageThree: {
        ...detailsData.managementInformation.submittedStageThree,
        combinationFlag:
          detailsData.managementInformation.submittedStageThree?.combinationFlag ?? false
      },
      submittedStageFour: {
        ...detailsData.managementInformation.submittedStageFour,
        relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle:
          detailsData.managementInformation.submittedStageFour
            ?.relocationAssistanceForQuitClaimOrVoluntaryTransferOfTitle ?? '',
        saleByBorrower: detailsData.managementInformation.submittedStageFour?.saleByBorrower ?? ''
      }
    },
    other: {
      ...detailsData.other,
      correspondenceLanguage: detailsData.other.correspondenceLanguage?.toString(),
      ...(detailsData.other.defaultStatusComment !== undefined && {
        defaultStatusComment: detailsData.other.defaultStatusComment
      }),
      ...(detailsData.other.defaultStatusReason !== undefined && {
        defaultStatusReason: detailsData.other.defaultStatusReason.toString()
      }),
      validLoan: Boolean(detailsData.other.validLoan)
    }
  };
};
